import { FC, Fragment, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { formatGraphData } from "../../../helpers/formatGraphData";
import graphDropdownOptions from "../../../helpers/graphDropdownOptions";
import { GRAPH_SCALE } from "../../../helpers/graphOptions";
import Loader from "../../Loader/Loader.component";
import GraphSubTitle from "../GraphComponents/GraphSubTitleSection/GraphSubTitle.component";
import GraphTitleSection from "../GraphComponents/GraphTitleSection/GraphTitleSection.component";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import { formatMoney } from "../../../helpers/formatter";
import BarChartSingle from "../../Charts/BarChartSingle/BarChartSingle.component";
import EmptyBarChartSingle from "../../Charts/BarChartSingle/EmptyBarChartSingle.component";
import useInvestmentGraph from "../../../custom-hooks/useInvestmentGraph";
import useRecentInvestments from "../../../custom-hooks/useRecentInvestments";

interface IInvestmentGraph {
  aggregatorId: number;
  currentCurrency: string;
  activeProductName: string;
}

const InvestmentsGraph: FC<IInvestmentGraph> = ({
  aggregatorId,
  currentCurrency,
  activeProductName,
}) => {
  const [filter, setFilter] = useState(graphDropdownOptions.thisWeek);
  const { data, status, error } = useInvestmentGraph({
    aggregatorId,
    filter,
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
  });
  const {
    data: recentInvestmentData,
    status: recentInvestmentStatus,
    error: recentInvestmentError,
  } = useRecentInvestments({
    currentCurrencyValue: currentCurrency,
    activeProductName: activeProductName,
  });
  const match = useRouteMatch();

  //JSX Template
  let RenderedComponent = null;

  if (status === "loading") {
    RenderedComponent = (
      <div className="d-flex justify-content-center align-items-center m-h-100">
        <Loader />
      </div>
    );
  } else if (error) {
    RenderedComponent = (
      <h5 className="text-danger text-center font-weight-bold">
        An error occured
      </h5>
    );
  } else if (data) {
    if (!data.length) {
      RenderedComponent = <EmptyBarChartSingle />;
    } else {
      RenderedComponent = (
        <BarChartSingle
          isDisbursement={true}
          graphData={formatGraphData(data)}
        />
      );
    }
  }
  return (
    <div>
      <div className="card">
        <div className="card-body p-3">
          <GraphTitleSection
            filter={filter}
            setFilter={setFilter}
            title="Investments"
          >
            <p className="text-dark">
              in {GRAPH_SCALE.toLocaleString("en-NG")}
            </p>
          </GraphTitleSection>
          <Fragment>{RenderedComponent}</Fragment>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body p-3">
          <GraphSubTitle
            text="Recent Investments"
            to={match.path + "investments"}
          />

          <Fragment>
            {recentInvestmentStatus === "loading" ? (
              <div className="d-flex justify-content-center align-items-center m-h-100">
                <Loader />
              </div>
            ) : recentInvestmentError ? (
              <h5 className="text-danger text-center font-weight-bold">
                An error occured
              </h5>
            ) : recentInvestmentData && recentInvestmentData.length ? (
              <ul className="dashboard-graph-list">
                {recentInvestmentData
                  .slice(0, 3)
                  .map((data: any, _i: number) => {
                    const { amount, funderName, currency, investmentId } = data;
                    return (
                      <li key={investmentId}>
                        <span className="dashbord-graph-list-name">
                          {funderName}
                        </span>{" "}
                        <span className="dashboard-graph-list-amount">
                          {formatMoney(amount, currency)}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <NoTableDataAvailable />
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default InvestmentsGraph;
