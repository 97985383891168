import React from "react";
import { Link } from "react-router-dom";

const LoanRequestDisbursement = () => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <h3 className="page-title ">Loans</h3>
        </div>

        <div className="">
          <Link
            to="/make-loan-request"
            className="btn advancly-btn  float-right"
          >
            Make Loan Request (s)
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoanRequestDisbursement;
