import React, { Fragment, useState, FC } from "react";
// import styles from "./BorrowersGraph.module.scss";
import { useRouteMatch } from "react-router-dom";
import { formatInvestorsGraphData } from "../../../helpers/formatGraphData";
import graphDropdownOptions from "../../../helpers/graphDropdownOptions";
import Loader from "../../Loader/Loader.component";
import GraphSubTitle from "../GraphComponents/GraphSubTitleSection/GraphSubTitle.component";
import GraphTitleSection from "../GraphComponents/GraphTitleSection/GraphTitleSection.component";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import DoughnutChart from "../../Charts/BarChart/DoughnutChart.component";
import EmptyDoughnutChart from "../../Charts/BarChart/EmptyDoughnutChart.component";
import useRecentInvestors from "../../../custom-hooks/useRecentInvestors";
import useInvestorGraph from "../../../custom-hooks/useInvestorGraph";

interface IInvestmentInvestorsGraph {
  aggregatorId: number;
  currentCurrency: string;
  activeProductName: string;
}

const InvestmentInvestorsGraph: FC<IInvestmentInvestorsGraph> = ({
  aggregatorId,
  currentCurrency,
  activeProductName,
}) => {
  const [filter, setFilter] = useState(graphDropdownOptions.thisWeek);
  const match = useRouteMatch();
  const { data, status, error } = useInvestorGraph({
    aggregatorId,
    filter,
  });
  const {
    data: recentInvestorsData,
    status: recentInvestorsStatus,
    error: recentInvestorsError,
  } = useRecentInvestors({
    currentCurrencyValue: currentCurrency,
    activeProductName,
  });
  //JSX Template
  let RenderedComponent = null;

  if (status === "loading") {
    RenderedComponent = (
      <div className="d-flex justify-content-center align-items-center m-h-100">
        <Loader />
      </div>
    );
  } else if (error) {
    RenderedComponent = (
      <h5 className="text-danger text-center font-weight-bold">
        An error occured
      </h5>
    );
  } else if (data) {
    if (!data.data || !data.data.length) {
      RenderedComponent = <EmptyDoughnutChart />;
    } else {
      RenderedComponent = RenderedComponent = (
        <DoughnutChart graphData={formatInvestorsGraphData(data?.data)} />
      );
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-body p-3">
          <GraphTitleSection
            filter={filter}
            setFilter={setFilter}
            title="Investors"
          >
            <div></div>

            {/* <div className={styles.alignGraphLabels}>
              <span className={styles.bgActive}>Active</span>
              <span className={styles.bgInActive}>Inactive</span>
            </div> */}
          </GraphTitleSection>
          <Fragment>{RenderedComponent}</Fragment>
        </div>
      </div>
      <div className="advancly-sub-wrapper mt-3">
        <div className=" p-3">
          <GraphSubTitle text="Recent Investors" to={match.path + "investor"} />

          <Fragment>
            {recentInvestorsStatus === "loading" ? (
              <div className="d-flex justify-content-center align-items-center m-h-100">
                <Loader />
              </div>
            ) : recentInvestorsError ? (
              <h5 className="text-danger text-center font-weight-bold">
                An error occured
              </h5>
            ) : recentInvestorsData && recentInvestorsData.length ? (
              <ul className="dashboard-graph-list">
                {recentInvestorsData
                  .slice(0, 3)
                  .map((data: any, _i: number) => {
                    const { isActive, name } = data;
                    return (
                      <li key={_i}>
                        <span className="text-capitalize dashbord-graph-list-name">
                          {name && name.toLowerCase()}
                        </span>{" "}
                        <span
                          className={`${isActive ? "active" : "inactive"} `}
                        >
                          {isActive ? "Active" : "Inactive"}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <NoTableDataAvailable />
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default InvestmentInvestorsGraph;
