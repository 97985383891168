import styles from "../../AllAggregatorLoans/AllDisbursedLoans/AllDisbursedLoansOverview.module.scss";
import { ReactComponent as ProgressIconTop } from "../../../svg/icons/progress_icon_top_colored.svg";
import { ReactComponent as ProgressIconDown } from "../../../svg/icons/progress_icon_down.svg";
import { ReactComponent as BorrowersPeopleIcon } from "../../../svg/icons/people-icon.svg";
import { ReactComponent as TotalMoneyIcon } from "../../../svg/icons/progress_money_icon.svg";
import AllDisbursedLoansMain from "./AllDisbursedLoansMain";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader.component";
import useLoanOverview from "../../../custom-hooks/useLoanOverview";
import useAggregatorActiveCurrencies from "../../../custom-hooks/useAggregatorActiveCurrencies";
import { useEffect, useState } from "react";
import { useQueryCache } from "react-query";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import LoaderColored from "../../../components/Loader/LoaderColored";

const AllDisbursedLoansOverview = () => {
  const [currentCurrency, setCurrentCurrency] = useState<any>("");
  const {
    data: aggregatorCurrencies,
    status: aggregatorCurrenciesStatus,
    error: aggregatorCurrenciesError,
  } = useAggregatorActiveCurrencies();
  const queryCache = useQueryCache();
  function currencyChangeHandler(e: any) {
    setCurrentCurrency(e.target.value);
    queryCache.invalidateQueries("getTransactionsOverview");
    queryCache.invalidateQueries("getTotalOutstanding");
    queryCache.invalidateQueries("getInvestmentOverview");
  }
  const {
    data: loanOverviewData,
    status: loanOverviewStatus,
    error: loanOverviewError,
  } = useLoanOverview({ currentCurrencyValue: currentCurrency });
  useEffect(() => {
    let defaultCurrency;
    aggregatorCurrencies?.data !== undefined
      ? (defaultCurrency = aggregatorCurrencies?.data?.includes("NGN")
          ? "NGN"
          : aggregatorCurrencies?.data?.includes("USD")
          ? "USD"
          : aggregatorCurrencies?.data.includes("KES")
          ? "KES"
          : "NGN")
      : (defaultCurrency = "");

    setCurrentCurrency(defaultCurrency);
  }, [aggregatorCurrencies]);
  return (
    <div>
      {loanOverviewError && (
        <p className="alert alert-danger small text-center">
          {loanOverviewError as string}
        </p>
      )}
      {aggregatorCurrenciesError && (
        <p className="alert alert-danger small text-center">
          {aggregatorCurrenciesError as string}
        </p>
      )}

      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          <h3 className="page-title ">Loans </h3>
        </div>

        <div className=" ">
          <Link to="/make-loan-request" className="btn advancly-btn  ">
            Make Loan Request (s)
          </Link>
        </div>
      </div>
      <div className="advancly-wrapper-container mt-3">
        <div className="d-flex align-items-center">
          <h5 className="color-advancly-dark-persian-indigo ">Overview</h5>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex">
              <CustomSelectDropdown
                value={currentCurrency}
                onChange={currencyChangeHandler}
                label="Currency"
                className="ml-4 mr-2"
              >
                {aggregatorCurrencies?.data?.map(
                  (currency: string, id: number) => (
                    <option key={id}>{currency}</option>
                  )
                )}
              </CustomSelectDropdown>

              {aggregatorCurrenciesStatus === "loading" && <Loader />}
            </div>
          </div>
        </div>
        <div className={`advancly-sub-wrapper ${styles.mainSubWrapper}`}>
          <div className={styles.loansOverviewContainer}>
            <div className={styles.loansOverViewSubContainer}>
              {loanOverviewStatus === "loading" ? (
                <LoaderColored />
              ) : (
                <>
                  <ProgressIconTop />
                  <div className={styles.overviewTextContainer}>
                    <p className={styles.loanOverviewHeading}>
                      No of Approved Loans
                    </p>{" "}
                    {loanOverviewData?.total_approved_loans >= 0 ? (
                      <p className={styles.loanOverviewParagraph}>
                        {loanOverviewData?.total_approved_loans}
                      </p>
                    ) : (
                      <small>Unavailable</small>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className={styles.loansOverViewSubContainer}>
              {loanOverviewStatus === "loading" ? (
                <LoaderColored />
              ) : (
                <>
                  <ProgressIconDown />
                  <div className={styles.overviewTextContainer}>
                    <p className={styles.loanOverviewHeading}>
                      No of Rejected Loans
                    </p>
                    {loanOverviewData?.total_rejected_loans >= 0 ? (
                      <p className={styles.loanOverviewParagraph}>
                        {loanOverviewData?.total_rejected_loans}
                      </p>
                    ) : (
                      <small>Unavailable</small>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.loansOverViewSubContainer}>
              {loanOverviewStatus === "loading" ? (
                <LoaderColored />
              ) : (
                <>
                  <BorrowersPeopleIcon />
                  <div className={styles.overviewTextContainer}>
                    <p className={styles.loanOverviewHeading}>
                      No of Active Loans
                    </p>
                    {loanOverviewData?.total_active_loans >= 0 ? (
                      <p className={styles.loanOverviewParagraph}>
                        {loanOverviewData?.total_active_loans}{" "}
                      </p>
                    ) : (
                      <small>Unavailable</small>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.loansOverViewSubContainer}>
              {loanOverviewStatus === "loading" ? (
                <LoaderColored />
              ) : (
                <>
                  <TotalMoneyIcon />
                  <div className={styles.overviewTextContainer}>
                    <p className={styles.loanOverviewHeading}>
                      No of Borrowers
                    </p>
                    {loanOverviewData?.total_borrowers >= 0 ? (
                      <p className={styles.loanOverviewParagraph}>
                        {loanOverviewData?.total_borrowers}{" "}
                      </p>
                    ) : (
                      <small>Unavailable</small>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <AllDisbursedLoansMain />
      </div>
    </div>
  );
};

export default AllDisbursedLoansOverview;
