import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getOfferLetterAndSla = async () => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.offerLetterAndSla}`,
    true
  );
  return data.data;
};

export default function useOfferLetterAndSla(viewHistory: boolean) {
  return useQuery("getOfferLetterAndSla", getOfferLetterAndSla, {
    enabled: viewHistory ? false : true,
    refetchOnWindowFocus: false,
  });
}
