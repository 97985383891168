import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import useRollOverDetails from "../../custom-hooks/useRollOverDetails";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { formatMoney } from "../../helpers/formatter";
import Loader from "../Loader/Loader.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import { accurateQuantifier } from "../utility";
interface IRolloverData {
  loan_amount: number;
  loan_amount_repay: number;
  status: string;
  pub_date: string;
  settlement_day: string;
  loan_ref: string;
  loan_tenure: string;
  repayment_type: string;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  parent_rollover_loan_reference: string;
  rollover_loan_reference: string;
}
const RollOverLoanDetails = ({
  parent_rollover_loan_reference,
}: {
  parent_rollover_loan_reference: string;
}) => {
  const { data, status, error } = useRollOverDetails({
    parent_rollover_loan_reference,
  });

  if (status === "loading")
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (error)
    return <h5 className="alert alert-danger small">An error occured</h5>;
  return (
    <Fragment>
      {/* <!-- Disbursements Table --> */}
      <div className="table-responsive">
        <table className="table-style">
          <thead className="">
            <tr>
              <th className="th-td-style th-style ">Loan Ref</th>
              <th className="th-td-style th-style ">Tenure</th>
              <th className="th-td-style th-style ">Repayment Frequency</th>
              <th className="th-td-style th-style ">Amount</th>
              <th className="th-td-style th-style ">Amount to Repay</th>
              <th className="th-td-style th-style ">Pub Date</th>
              <th className="th-td-style th-style ">Settlement Date</th>
              <th className="th-td-style th-style ">Parent R/V Loan Ref</th>
              <th className="th-td-style th-style ">Roll Over Loan Ref</th>
              <th className="th-td-style th-style ">Loan Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((loans: IRolloverData, _id: number) => {
                const {
                  loan_amount,
                  loan_amount_repay,
                  status,
                  pub_date,
                  settlement_day,
                  loan_ref,
                  loan_tenure,
                  repayment_type,
                  currency,
                  parent_rollover_loan_reference,
                  rollover_loan_reference,
                } = loans;
                return (
                  <tr key={_id}>
                    <td className="th-td-style ">{loan_ref}</td>
                    <td className="th-td-style ">
                      {accurateQuantifier(loan_tenure, "day")}
                    </td>
                    <td className="th-td-style ">{repayment_type}</td>
                    <td className="th-td-style ">
                      {formatMoney(Number(loan_amount), currency)}
                    </td>
                    <td className="th-td-style ">
                      {formatMoney(Number(loan_amount_repay), currency)}
                    </td>
                    <td className="th-td-style ">
                      {pub_date?.substring(0, 10)}
                    </td>
                    <td className="th-td-style ">
                      {settlement_day ? settlement_day?.substring(0, 10) : ""}
                    </td>
                    <td className="th-td-style ">
                      {parent_rollover_loan_reference}
                    </td>
                    <td className="th-td-style ">{rollover_loan_reference}</td>
                    <td className="th-td-style ">
                      <span className={attachClassNameToTag(status)}>
                        {attachClassNameToTag(status)}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoTableDataAvailable />
            )}
          </tbody>
        </table>
      </div>
      {/* <!-- End Disbursements Table --> */}
    </Fragment>
  );
};
//@ts-ignore
export default withRouter(RollOverLoanDetails);
