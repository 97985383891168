import React, { useRef, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../img/DeleteIcon.svg";
import { deleteData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { queryCache } from "react-query";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { errorHandler } from "../../../../helpers/errorHandler";
import styles from "./GetStartedUploadDocumentsNew.module.scss";
import Loader from "../../../../components/Loader/Loader.component";

const DeleteExistingUploadedFile = ({
  aggregatorDocumentId,
}: {
  aggregatorDocumentId: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dismissRef = useRef<HTMLButtonElement>(null);
  const onSubmit = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await deleteData(
        `${apiEndpoints.DELETE_UPLOADED_DOCUMENT}/${aggregatorDocumentId}`,
        {},
        true
      );
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(res?.message);
      setTimeout(() => {
        setSuccess(false);
        dismissRef.current?.click();
      }, 3000);
      // Refetch list
      queryCache.invalidateQueries("getListOfDocumentsAlreadyUploaded");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "DeleteExistingUploadedFile.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));

      setTimeout(() => {
        setError(false);
        dismissRef.current?.click();
      }, 4000);
    }
  };
  return (
    <div
      className={`modal fade `}
      id="deleteUploadedDocsModal"
      tabIndex={-1}
      aria-labelledby="deleteuploadedDocsLabel"
      aria-hidden="true"
    >
      <div className={`modal-dialog ${styles.delete_container}`}>
        <div className="modal-content">
          {error && <p className="alert alert-danger small row">{error}</p>}
          {success && (
            <p className="alert alert-success small">{successMessage}</p>
          )}
          <div className="modal-body d-flex flex-column align-items-center ">
            <div className={`${styles.deleteIcon} `}>
              <DeleteIcon />
            </div>
            <h5
              className={`${styles.delete_heading} color-advancly-dark-persian-indigo`}
            >
              Delete File?
            </h5>
            <p className={`${styles.delete_paragraph} color-grey-purple`}>
              Are you sure you want to delete this file? this action is
              irreversible
            </p>
            <div className="d-flex flex-column">
              <button
                type="button"
                className="btn advancly-red-btn2 mt-3"
                onClick={onSubmit}
              >
                Delete
                {loading && <Loader />}
              </button>
              <button
                type="button"
                ref={dismissRef}
                className="close btn advancly-nobg-btn mt-3 mb-4 "
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteExistingUploadedFile;
