import React from "react";
import DashboardCard from "../../components/DashboardCard/DashboardCard.component";
import { formatMoneyNoDecimal } from "../../helpers/formatterNoDecimal";
import { ReactComponent as ProgressIconDown } from "../../svg/icons/progress_icon_down.svg";
import { ReactComponent as ProgressIconPeople } from "../../svg/icons/progress_people_icon.svg";
import { ReactComponent as ProgressIconMoney } from "../../svg/icons/progress_money_icon.svg";
import { ReactComponent as ProgressIconTop } from "../../svg/icons/progress_icon_top_colored.svg";

import LoanDashboardGraphs from "../../components/DashboardGraphs/LoanDashboardGraphs.component";

const LoanDashboardTab = ({
  currentCurrency,
  transactionsOverview,
  trasactionOverviewStatus,
  loanCount,
  loanCountStatus,
  totalOutstandingLoans,
  totalOutstandingStatus,
  activeProductName,
}: {
  currentCurrency: any;
  transactionsOverview: {
    total_disbursement: number;
    currency: "NGN" | "USD" | "KES" | "#" | "$" | "KSh";
    total_repayment: number;
  };
  trasactionOverviewStatus: string;
  loanCount: number;
  loanCountStatus: string;
  totalOutstandingLoans: {
    totalAmount: number;
    currency: "NGN" | "USD" | "KES" | "#" | "$" | "KSh";
  };
  totalOutstandingStatus: string;
  activeProductName: string;
}) => {
  // totalOutstandingStatus = { totalOutstandingStatus };
  return (
    <div className=" ">
      {/* DASHBOARD LOANS */}
      <div className=" advancly-wrapper-container mb-3">
        <div className="dashboardComponent advancly-sub-wrapper ">
          <div>
            <DashboardCard
              text="Total amount disbursed"
              value={
                transactionsOverview &&
                formatMoneyNoDecimal(
                  Math.trunc(transactionsOverview?.total_disbursement),
                  transactionsOverview?.currency
                )
              }
              status={trasactionOverviewStatus}
            >
              {" "}
              <ProgressIconTop />
            </DashboardCard>
          </div>
          <div>
            <DashboardCard
              text="Total repayment"
              value={
                transactionsOverview &&
                formatMoneyNoDecimal(
                  transactionsOverview?.total_repayment,
                  transactionsOverview?.currency
                )
              }
              status={trasactionOverviewStatus}
            >
              <ProgressIconDown />
            </DashboardCard>
          </div>
          <div>
            <DashboardCard
              text="Total outstanding loan"
              value={
                totalOutstandingLoans &&
                formatMoneyNoDecimal(
                  totalOutstandingLoans?.totalAmount,
                  totalOutstandingLoans?.currency
                )
              }
              status={totalOutstandingStatus}
            >
              <ProgressIconPeople />
            </DashboardCard>
          </div>
          <div>
            <DashboardCard
              text="Loan counts"
              value={loanCount >= 1 ? loanCount : 0}
              status={loanCountStatus}
            >
              <ProgressIconMoney />
            </DashboardCard>
          </div>
        </div>
      </div>
      <LoanDashboardGraphs
        currentCurrency={currentCurrency}
        activeProductName={activeProductName}
      />
      {/* DASHBOARD LOANS */}
    </div>
  );
};

export default LoanDashboardTab;
