import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type Taggregator_id = number;
const getEligibilityStatus = async (aggregator_id: Taggregator_id) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getEligibilityStatus}?aggregator_id=${aggregator_id}`,
    true
  );
  return data?.data;
};

export default function useEligibilityStatus(aggregator_id: Taggregator_id) {
  return useQuery(
    [aggregator_id, "getEligibilityStatus"],
    getEligibilityStatus,
    {
      refetchOnWindowFocus: false,
    }
  );
}
