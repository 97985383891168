import React, { useEffect, useState } from "react";
import OnboardingStep from "../../../components/OnboardingStep/OnboardingStep.component";
import styles from "../GetStarted.module.scss";
import ROUTES from "../../../helpers/ROUTES";
import { Link, useHistory, useLocation } from "react-router-dom";
import Alert from "../../../components/Alert/Alert.component";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import Loader from "../../../components/Loader/Loader.component";
import { errorHandler } from "../../../helpers/errorHandler";
import useEligibilityStatus from "../../../custom-hooks/useEligibilityStatus";
// import PageLoader from "../../../components/PageLoader/PageLoader.component";
import advanclyLogo from "../../../img/advancly_logo.svg";
import { useAuth } from "../../../Context/auth.context";
import PageLoaderAnimation from "../../../components/PageLoader/PageLoaderAnimation";
import { putData } from "../../../apis/apiMethods";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { useQueryCache } from "react-query";
import { setLoginInfo } from "../../../helpers/setLoginInfo";
import CancelPromptGetStarted from "./GetStartedUploadDocuments/CancelPromptGetStarted";

export default function GetStartedOnboarding() {
  const { aggregatorDetails, setEligibilityData } = useAuth();
  const queryCache = useQueryCache();

  const history = useHistory();
  const location = useLocation<any>();
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [error, setError] = useState<any>(null);
  const [promptModal, setPromptModal] = useState(false);
  const {
    data,
    error: eligibilityError,
    status: eligibilityStatus,
  } = useEligibilityStatus(aggregatorDetails?.aggregator_id);

  const requestOnboarding = async () => {
    setError(null);

    setLoading(true);
    try {
      await postDataWithDotNet(apiEndpoints.onboardAggregatorRequest, {}, true);
      setLoading(false);
      history.push(ROUTES.GET_STARTED_ONBOARDING_FINAL);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "GetStartedOnboarding.tsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const cancelOnboarding = async () => {
    setError(null);
    setLoadingCancel(true);
    try {
      await putData(apiEndpoints.cancelOnboardingRequest, {}, true);
      setLoadingCancel(false);
      queryCache.invalidateQueries("getEligibilityStatus");
      //updates eligibility status on local storage

      const { eligibilityResult } = await setLoginInfo();

      setEligibilityData(eligibilityResult);

      history.push(ROUTES.DASHBOARD);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "GetStartedOnboarding.jsx",
        },
      });
      setLoadingCancel(false);
      setError(errorHandler(error));
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedOnboarding.jsx",
      isLoggedIn: true,
    });
  }, []);

  if (eligibilityStatus === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoaderAnimation />
      </div>
    );
  if (eligibilityError)
    return (
      <div className="d-flex justify-content-center p-3">
        <h3>{errorHandler(eligibilityError)}</h3>
      </div>
    );
  return (
    <div className={`ml-5 mt-5  `}>
      <div className={styles.onboardingLogoLink}>
        <Link className={styles.sparkLogoLink} to="/">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>

        <div className={` mt-3`} style={{ width: "60%" }}>
          {" "}
          <small
            className="color-mid-blue  cursor-pointer font-weight-bold  "
            onClick={() => history.push("/get-started")}
          >
            &#8592; Go back
          </small>
        </div>
      </div>
      <div className={``}>
        <div className={`${styles.newGetStartedInnerComponent}`}>
          {location && location.state && location.state.successMessage && (
            <Alert message={location?.state.successMessage} type="success" />
          )}
          {error && <Alert message={error} />}
          <h3 className="page-title mt-4 mb-4">Onboard My Business</h3>
          <p className="text-left">
            To onboard your business and start accessing funds, kindly <br />{" "}
            complete the tasks below. When you’re done, please click <br /> the
            “Request Onboarding” button
          </p>
          <div className="mb-3 w-100">
            {((data && data?.statusCode >= 0 && data?.statusCode <= 6) ||
              data?.statusCode === 23) && (
              <OnboardingStep
                activeButton={data && data?.statusCode === 0 ? true : false}
                num={1}
                title="Update Business Information"
                text="Please tell us more about your business"
                buttonLink={ROUTES.GET_STARTED_ONBOARDING_BUSINESS_INFO}
                stepCompleted={data && data?.statusCode > 0 ? true : false}
              />
            )}
            <OnboardingStep
              activeButton={
                data && data?.statusCode === 1
                  ? true
                  : data && data?.subsequentStatusCode === 1
                  ? true
                  : false
              }
              num={
                (data && data?.statusCode >= 1 && data?.statusCode < 9) ||
                data?.statusCode === 23
                  ? 2
                  : (data &&
                      data?.subsequentStatusCode >= 1 &&
                      data?.subsequentStatusCode <= 9) ||
                    data?.subsequentStatusCode === 23
                  ? 1
                  : 2
              }
              title="Take Eligibility Test"
              text="Answer a few questions to help us know if we’re a right fit"
              buttonLink={ROUTES.GET_STARTED_ONBOARDING_ELIGIBLITY_TEST}
              stepCompleted={
                (data && data.statusCode >= 2 && data?.statusCode < 9) ||
                data?.statusCode === 23
                  ? true
                  : data &&
                    data.subsequentStatusCode >= 2 &&
                    (data.subsequentStatusCode < 9 ||
                      data?.subsequentStatusCode === 23)
                  ? true
                  : false
              }
            />
            <OnboardingStep
              activeButton={
                (data && data?.statusCode >= 2 && data?.statusCode < 9) ||
                data?.statusCode === 23
                  ? true
                  : (data &&
                      data?.subsequentStatusCode >= 2 &&
                      data?.subsequentStatusCode < 9) ||
                    data?.subsequentStatusCode === 23
                  ? true
                  : false
              }
              num={
                data && data?.statusCode >= 1 && data?.statusCode < 9
                  ? 3
                  : (data &&
                      data?.subsequentStatusCode >= 1 &&
                      data?.subsequentStatusCode <= 9) ||
                    data?.subsequentStatusCode === 23
                  ? 2
                  : 3
              }
              title="Upload Documents"
              text="Upload documents to allow our team do their due diligence assessment"
              buttonLink={ROUTES.GET_STARTED_ONBOARDING_UPLOAD_DOCUMENT}
              stepCompleted={
                (data && data.statusCode >= 4 && data?.statusCode < 9) ||
                data.statusCode === 23
                  ? true
                  : (data &&
                      data.subsequentStatusCode >= 4 &&
                      data.subsequentStatusCode < 9) ||
                    data.subsequentStatusCode === 23
                  ? true
                  : false
              }
            />
          </div>
        </div>
        <div className={`${styles.requestOnboarding}`}>
          {data &&
            data.subsequentStatusCode >= 1 &&
            data.subsequentStatusCode < 5 && (
              <button
                className={`btn  btn-sm btn-danger transition-3d-hover mt-2 mb-4 mr-2 ${styles.cancelboarding}`}
                onClick={() => {
                  setPromptModal(true);
                }}
              >
                Cancel Onboarding
                {loadingCancel && <Loader />}
              </button>
            )}
          <button
            className={
              data && data?.statusCode < 4
                ? "btn advancly-btn btn-sm transition-3d-hover mt-2 mb-4"
                : data.subsequentStatusCode >= 1 &&
                  data.subsequentStatusCode < 4
                ? "btn advancly-btn btn-sm transition-3d-hover mt-2 mb-4"
                : "btn advancly-btn btn-sm  mt-2 mb-4"
            }
            onClick={requestOnboarding}
            disabled={
              (data && data?.statusCode >= 0 && data?.statusCode < 4) ||
              data?.statusCode === 23
                ? true
                : (data &&
                    data.subsequentStatusCode >= 1 &&
                    data.subsequentStatusCode < 4) ||
                  data?.subsequentStatusCode === 23
                ? true
                : false
            }
          >
            Request Onboarding
            {loading && <Loader />}
          </button>
        </div>
      </div>
      {promptModal && (
        <CancelPromptGetStarted
          cancelOnboarding={cancelOnboarding}
          setPromptModal={setPromptModal}
        />
      )}
    </div>
  );
}
